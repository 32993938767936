@import './nav-container.scss';
@import './header.scss';
@import './responsivenes.scss';
@import './input.scss';
@import './loader.scss';
@import './game-details.scss';

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #f6f6f9;
	color: #06021f;
}

.divider {
	max-width: 1240px;
	border: 1px solid #dde0e4;
}
