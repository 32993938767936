select,
input {
	margin-bottom: 30px;
	height: 60px;
	width: 100%;
	background: #f6f6f9;
	border: 1px solid #e1e3e6;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 20px;
	font-size: 16px;
	outline: none !important;
}

input::placeholder{
	font-size: 16px;
	color: #9199ad;
	opacity: 1;
}

input:-ms-input-placeholder {
	font-size: 16px;
	color: #9199ad;
	opacity: 1;
}

input::-ms-input-placeholder {
	font-size: 16px;
	color: #9199ad;
	opacity: 1;
}

select.not-default {
	color: #06021f;
}

select {
	color: #9199ad;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
	background-position: calc(100% - 20px) calc(1em + 11px), calc(100% - 15px) calc(1em + 11px), calc(100% - 2.5em) 1em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;
}

input:focus:not([value='']):invalid,
select:focus:not([value='']):invalid {
	border: rgb(219, 60, 60) 1px solid;
}
