.nav-container {
	position: relative;
	margin: auto;

	ul {
		padding: 0;
		display: inline-flex;

		li {
			list-style-type: none;
		}
	}
}
