.game-details-container {
	margin: 100px auto 0;

	.main-info {
		.subtitle {
			text-align: center;
			color: #d84040;
			margin-bottom: 10px;
			font-weight: bold;
		}

		.description {
			margin: 30px auto;
			max-width: 900px;
			font-weight: 300;
			font-size: 20px;
			line-height: 32px;
			text-align: center;
			color: #404040;
		}

		.store-links {
			display: flex;
			justify-content: center;
			gap: 20px;

			img {
				width: 150px;
				margin: inherit;
				cursor: pointer;

				&:hover {
					box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
					border-radius: 5px;
				}
			}
		}

		@media screen and (max-width: 425px) {
			.store-links {
				img {
					width: 140px;
				}
			}
		}
	}

	.divider {
		margin: 100px auto;

		@media screen and (max-width: 650px) {
			margin: 40px auto 80px;
		}
	}

	.game-info {
		max-width: 1350px;
		display: flex;
		gap: 100px;
		margin: 0 auto 150px;
		justify-content: center;

		.preview {
			position: relative;
			margin-left: 110px;

			.preview-wrapper {
				width: fit-content;
				position: relative;
				margin: auto;

				img {
					z-index: 200;
					position: relative;
					pointer-events: none;
				}
			}

			.frame {
				position: absolute;
				height: 94%;
				width: 93%;
				top: 0;
				margin: 18px 0 0 13px;
				pointer-events: none;
			}

			iframe {
				position: relative;
				height: 100%;
				width: 100%;
				border: none;
				border-radius: 45px;
				pointer-events: all;
			}
		}

		.stats {
			display: flex;

			.main-stats {
				display: flex;
				gap: 50px;
				margin-bottom: 60px;
				margin-top: 20px;

				.stat {
					.amount {
						font-weight: bold;
						font-size: 72px;
						line-height: 80px;
					}

					.type {
						text-align: left;
						font-size: 20px;
						line-height: 32px;
						text-transform: uppercase;
						color: #43424b;
					}
				}
			}

			.value-points {
				ul {
					list-style-image: url(../assets/icons/checkmark-icon.svg);
					padding: 0 25px;

					li {
						margin-bottom: 50px;
						font-size: 24px;
						line-height: 32px;

						span {
							position: relative;
							left: 20px;
							top: -4px;
						}
					}
				}
			}

			.enemy {
				margin-top: auto;
				margin-bottom: auto;

				img {
					width: 120px;
				}
			}
		}

		@media screen and (max-width: 1300px) {
			gap: 70px;

			.preview {
				margin-left: 70px;

				img {
					max-width: 400px;
					width: 100%;
				}
			}

			.stats {
				.main-stats {
					gap: 35px;
					margin-bottom: 40px;

					.stat {
						.amount {
							font-size: 48px;
							line-height: 48px;
						}

						.type {
							font-size: 16px;
							line-height: 25px;
						}
					}
				}

				.value-points {
					ul {
						li {
							font-size: 18px;
							margin-bottom: 30px;
							// white-space: nowrap;
						}
					}
				}
			}
		}

		@media screen and (max-width: 980px) {
			.preview {
				margin: 0;
			}

			.stats {
				.enemy {
					img {
						width: 90px;
					}
				}
			}
		}

		@media screen and (max-width: 768px) {
			.stats {
				.enemy {
					display: none;
				}
			}
		}

		@media screen and (max-width: 650px) {
			display: block;
			margin: 0 auto 50px;

			.preview {
				text-align: center;
			}

			.stats {
				margin-top: 40px;

				.wrapper {
					margin: auto;
				}

				.main-stats {
					justify-content: center;
				}
			}
		}
	}

	.all-heroes-container {
		margin: auto;
		max-width: 1100px;
		margin-bottom: 100px;

		svg {
			width: 100%;
			margin: auto;
			height: 100%;
		}

		@media screen and (max-width: 820px) {
			margin-bottom: 50px;
		}
	}
}
