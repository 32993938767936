.main-responsivenes {
	min-height: calc(100vh - 500px);
	margin: 50px 10%;

	@media screen and (max-width: 1050px) {
		margin: 50px 5%;
	}

	@media screen and (max-width: 820px) {
		margin: 30px 20px;
	}
}
